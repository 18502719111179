<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />

    <li class="nav-item logout-menu-item">
      <a
        class="d-flex align-items-center logout-menu-link"
        @click="logout"
      >
        <span class="logout-icon mr-1" />
        <span class="menu-title text-truncate">Logout</span>
      </a>
      <span class="switch-skin-icon d-none">
        <dark-Toggler class="d-none d-lg-block" />
      </span>
    </li>

  </ul>

</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    useJwt,
    DarkToggler,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>

<style scoped>

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li.logout-menu-item:last-child {
  margin-bottom: -10px !important;
}
.vertical-layout.vertical-menu-modern .main-menu .navigation > li.logout-menu-item > a .logout-icon{
    height: 20px;
    width: 20px;
    font-size: 1.45rem;
    margin-right: 1.1rem;
    flex-shrink: 0;
}

  .logout-menu-item {
    position: absolute;
    bottom: 30px;
    width: 100%;
  }

  .logout-icon {
      display: block;
      float: left;
      height:20px;
      width: 20px;
      background-image: url('~@/assets/images/icons/general-icons/logout.png');
      background-repeat: no-repeat;
  }

  .dark-layout  .logout-icon {
      background-image: url('~@/assets/images/icons/general-icons/logout_white.png');
  }

    .logout-menu-link {
      width:75%;
      float: left;
    }

  .switch-skin-icon {
      display: block;
      float: right;
      position: absolute;
      right: 0;
      top: 0;
  }
</style>
