export default [
  {
    title: 'Home',
    route: 'home',
    permission: '*',
    icon: 'HomeIcon',
  },
  /* start to remove */
  {
    title: 'RoadMap',
    route: 'roadmap',
    permission: '*',
    icon: 'MapIcon',
    children: [
      {
        title: 'RoadMap',
        route: 'roadmap',
        permission: '*',
        icon: 'MapIcon',
      },
      {
        title: 'Edit RoadMap',
        route: 'roadmap.edit',
        permission: '*',
        icon: 'EditIcon',
      },
    ],
  },
  /* end to remove */
  {
    title: 'Admins',
    route: 'admins',
    permission: 'View Admins',
    icon: 'UserCheckIcon',
    children: [
      {
        title: 'Admins',
        route: 'admins',
        permission: 'View Admins',
        icon: 'UserCheckIcon',
      },
      {
        title: 'Roles',
        route: 'roles',
        permission: 'View Roles',
        icon: 'StopCircleIcon',
      },
    ],
  },
  {
    title: 'Customers',
    route: 'customers',
    permission: 'View Customers',
    icon: 'UsersIcon',
  },
  {
    title: 'Rides',
    route: 'rides',
    permission: 'View Rides',
    icon: 'MapIcon',
  },
  {
    title: 'Scooters',
    permission: 'View Scooters',
    route: 'scooters',
    icon: 'BoxIcon',
  },
  /* start to remove */
  {
    title: 'Factory Monitor',
    route: 'factory',
    permission: 'View Factory Monitor',
    icon: 'MonitorIcon',
    children: [
      {
        title: 'Factory Monitor',
        route: 'factory',
        permission: 'View Factory Monitor',
        icon: 'MonitorIcon',
      },
      {
        title: 'Calendar',
        route: 'factory.calendar',
        permission: 'View Factory Monitor',
        icon: 'CalendarIcon',
      },
    ],
  },
  {
    title: 'Statistics',
    route: 'statistics',
    permission: 'View Statistics',
    icon: 'PieChartIcon',
  },
  {
    title: 'City 2022',
    permission: '*',
    icon: 'AlertTriangleIcon',
    children: [
      {
        title: 'Issues',
        route: 'issues',
        permission: '*',
        icon: 'AlertTriangleIcon',
      },
      // {
      //   title: 'latest issues',
      //   route: 'latest_issues',
      //   permission: '*',
      //   icon: 'AlertTriangleIcon',
      // },
      {
        title: 'Customers list',
        route: 'city_2022_customers_list',
        permission: '*',
        icon: 'UsersIcon',
      },
    ],
  },
  // {
  //   title: 'Targets',
  //   route: 'target',
  //   permission: '*',
  //   icon: 'TargetIcon',
  // },
  {
    title: 'Service locators',
    route: 'service-locators',
    permission: '*',
    icon: 'ToolIcon',
  },
  // {
  //   title: 'Targets',
  //   route: 'target',
  //   permission: '*',
  //   icon: 'TargetIcon',
  // },
  {
    title: 'Logs',
    permission: 'View Logs',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'User logs',
        route: 'logs',
        permission: 'View Logs',
        icon: 'BookOpenIcon',
      },
      {
        title: 'Admin logs',
        route: 'admin-logs',
        permission: 'View Logs',
        icon: 'BookOpenIcon',
      },
    ],
  },
  {
    title: 'System',
    route: 'maintenance',
    permission: 'View Maintenance',
    icon: 'ToolIcon',
  },
  {
    title: 'About',
    route: 'release-notes',
    permission: '*',
    icon: 'HelpCircleIcon',
  },
  /* end to remove */
  {
    title: 'Configurator',
    route: 'configurator',
    permission: '*',
    icon: 'SlidersIcon',
  },
  // {
  //   title: 'Issues',
  //   route: 'issues',
  //   permission: '*',
  //   icon: 'AlertTriangleIcon',
  // },
]
